<!--
 * @Description: 订单每日添加
 * @Author: 琢磨先生
 * @Date: 2022-12-19 17:30:35
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 10:03:32
-->

<template>
  <el-button type="primary" size="small" @click="onOpen" round icon="plus" v-power="'seller/v1/expense/record/add_orderEveryday'"
    >订单每日</el-button
  >
  <el-drawer
    title="添加订单每日费用"
    v-model="visibleDrawer"
    direction="rtl"
    size="1100px"
    :destroy-on-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-scrollbar v-loading="loading">
      <el-timeline>
        <el-timeline-item>
          <p>1、订单选择</p>
          <div style="margin-bottom: 20px">
            <el-date-picker
              v-model="query.dates"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              type="daterange"
              @change="datePickerChange"
              unlink-panels
              start-placeholder="入住开始日期"
              end-placeholder="入住结束日期"
              :clearable="false"
            ></el-date-picker>
          </div>
          <el-table
            :data="order_list"
            border
            height="450"
            row-key="id"
            @selection-change="orderSelectionChange"
          >
            <el-table-column
              label
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column label="订单" prop>
              <template #default="scope">
                <div>
                  <b>{{ scope.row.house_no }}</b>
                  <el-tag size="small" style="margin-left: 10px">{{
                    scope.row.order_type_text
                  }}</el-tag>
                </div>
                <div>
                  姓名：{{ scope.row.contact_name }}、入离：{{
                    scope.row.checkin_date
                  }}~{{ scope.row.checkout_date }}、共{{ scope.row.days }}天
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-timeline-item>
        <el-timeline-item>
          <p>2、费用设置</p>
          <el-form :model="form" ref="form" :rules="rules" :inline="true">
            <el-form-item label="费用项" prop="expense_item_id">
              <el-select v-model="form.expense_item_id">
                <el-option
                  v-for="item in expenseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <div>
            <el-table :data="selection_list" border height="500">
              <el-table-column label="订单" width="320">
                <template #default="scope">
                  <div>
                    <b>{{ scope.row.house_no }}</b>
                    <el-tag size="small" style="margin-left: 10px">{{
                      scope.row.order_type_text
                    }}</el-tag>
                  </div>
                  <div>姓名：{{ scope.row.contact_name }}</div>
                  <div>
                    入离：{{ scope.row.checkin_date }}~{{
                      scope.row.checkout_date
                    }}、共{{ scope.row.days }}天
                  </div>
                </template>
              </el-table-column>
              <el-table-column label>
                <template #header>
                  <el-form :model="form" :inline="true" label-position="top">
                    <el-form-item label="统一费用">
                      <el-input
                        v-model="form.decimal_amount"
                        placeholder="费用金额"
                        @input="allAmountChange"
                        style="width: 120px"
                      >
                        <template #prefix>￥</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="统一数量">
                      <el-input
                        v-model="form.number"
                        @input="allNumberChange"
                        placeholder
                        style="width: 120px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="统一单价">
                      <el-input
                        v-model="form.decimal_unit_price"
                        @input="allPriceChange"
                        placeholder
                        style="width: 120px"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="统一成本">
                      <el-input
                        v-model="form.decimal_cost_amount"
                        @input="allCostChange"
                        placeholder
                        style="width: 120px"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </template>
                <template #default="scope">
                  <div
                    v-for="x in scope.row.expense_items"
                    :key="x"
                    class="expense_item"
                  >
                    <div>{{ x.start_date }}~{{ x.end_date }}</div>
                    <el-form
                      :model="x"
                      :rules="rules"
                      :inline="true"
                      :ref="(el) => (x.ref_form = el)"
                    >
                      <el-form-item label="金额" prop="decimal_amount">
                        <el-input
                          v-model="x.decimal_amount"
                          placeholder
                          style="width: 100px"
                        >
                          <template #prefix>￥</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="数量" prop="number">
                        <el-input
                          v-model="x.number"
                          placeholder
                          style="width: 80px"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="单价">
                        <el-input
                          v-model="x.decimal_unit_price"
                          placeholder
                          style="width: 100px"
                        >
                          <template #prefix>￥</template>
                        </el-input>
                      </el-form-item>
                      <el-form-item label="实际成本">
                        <el-input
                          v-model="x.decimal_cost_amount"
                          placeholder
                          style="width: 100px"
                        >
                          <template #prefix>￥</template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-drawer>
</template>

<script>
import date_util from "../../../utils/date_util";
export default {
  data() {
    return {
      saving: false,
      visibleDrawer: false,
      loading: false,
      query: {},

      //订单列表
      order_list: [],
      //选择的数据
      selection_list: [],
      form: {},
      rules: {
        expense_item_id: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        decimal_amount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
      },
    };
  },
  props: ["expenseList"],
  emits: ["success"],
  created() {
    // var dt = new Date();
    // var start_date = date_util.formatDate(
    //   new Date(dt.getFullYear(), dt.getMonth(), 1)
    // );
    // var end = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    // var end_date = date_util.formatDate(end);
    // this.query.dates = [start_date, end_date];
    // this.loadData();
  },
  methods: {
    /**
     * 弹出框
     */
    onOpen() {
      this.visibleDrawer = true;
      this.form = {};
      this.selection_list = [];

      var dt = new Date();
      var start_date = date_util.formatDate(
        new Date(dt.getFullYear(), dt.getMonth(), 1)
      );
      var end = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
      var end_date = date_util.formatDate(end);
      this.query.dates = [start_date, end_date];
      this.loadData();
    },
    /**
     * 日期选择更改
     */
    datePickerChange() {
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/expense/record/order", {
          start_date: this.query.dates[0],
          end_date: this.query.dates[1],
        })
        .then((res) => {
          if (res.code == 0) {
            this.order_list = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
          this.selection_list = [];
        });
    },

    /**
     * 选择
     */
    orderSelectionChange(nodes) {
      this.selection_list = nodes;
    },

    /**
     * 统一金额设置
     */
    allAmountChange(val) {
      if (!isNaN(val)) {
        this.selection_list.forEach((item) => {
          item.expense_items.forEach((x) => {
            x.decimal_amount = val;
          });
        });
      }
    },
    /**
     * 统一数量
     */
    allNumberChange(val) {
      if (!isNaN(val)) {
        this.selection_list.forEach((item) => {
          item.expense_items.forEach((x) => {
            x.number = Math.floor(val);
          });
        });
      }
    },
    /**
     * 单价
     */
    allPriceChange(val) {
      if (!isNaN(val)) {
        this.selection_list.forEach((item) => {
          item.expense_items.forEach((x) => {
            x.decimal_unit_price = val;
          });
        });
      }
    },
    /**
     * 成本
     */
    allCostChange(val) {
      if (!isNaN(val)) {
        this.selection_list.forEach((item) => {
          item.expense_items.forEach((x) => {
            x.decimal_cost_amount = val;
          });
        });
      }
    },
    /**
     * 提交保存
     */
    onSubmit() {
      if (this.selection_list.length <= 0) {
        this.$message.warning("请选择订单");
        return;
      }
      if (!this.form.expense_item_id) {
        this.$refs.form.validate();
        return;
      }

      for (var i in this.selection_list) {
        var item = this.selection_list[i];
        for (var j in item.expense_items) {
          var x = item.expense_items[j];
          if (!x.decimal_amount) {
            this.$message.error(
              `${item.house_no}、${item.contact_name} 金额必填`
            );
            x.ref_form.validate();
            return;
          }
        }
      }
      this.saving = true;
      this.$http
        .post("seller/v1/expense/record/add_OrderEveryday", {
          expense_item_id: this.form.expense_item_id,
          list: this.selection_list,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.visibleDrawer = false;
            this.$emit("success");
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style  scoped>
.expense_item {
  padding: 15px 0;
  border-bottom: 1px solid var(--el-border-color);
}
.expense_item:last-child {
  border-bottom: 0;
}
.expense_item .el-form-item {
  margin-bottom: 0;
  margin-right: 20px;
}
.expense_item .el-form-item:last-child {
  margin-right: 0;
}
</style>